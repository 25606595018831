import React from 'react'
import { Link, graphql } from 'gatsby'
import ReactMarkdown from 'react-markdown'

import Button from '../components/Button'
import GradientContainer from '../components/GradientContainer'
import Hero from '../components/Hero'
import NavigationBar from '../components/NavigationBar'
import FancyImage from '../components/FancyImage'
import Paragraph from '../components/Paragraph'
import SectionHeader from '../components/SectionHeader'
import Footer from '../components/Footer'
import { SEO } from '../components/SEO'

const about = ({ data }) => {
  const d = data.aboutYaml

  return (
    <div>
      <GradientContainer>
        <div className='container mx-auto'>
          <div className='md:mb-24'>
            <NavigationBar />
            <Hero
              title={d.heading}
              description={<AboutDescription desc={d.subheading} />}
              featured={
                <FancyImage image={d.header_image} classes='bg-dark' x={20} y={20} />
              }
            />
          </div>
        </div>
      </GradientContainer>
      <main className='mb-16'>
        <div className='container p-4 mx-auto'>
          <SectionHeader>NAICS Codes</SectionHeader>
          <Paragraph>
            <ReactMarkdown>{data.servicesYaml.naics_codes}</ReactMarkdown>
          </Paragraph>
          <SectionHeader>Our Philosophy</SectionHeader>
          <div className='flex flex-col justify-between gap-16 lg:flex-row'>
            <div className='flex-1'>
              <Paragraph>
                <ReactMarkdown>{d.our_philosophy}</ReactMarkdown>
              </Paragraph>
            </div>
            <div>
              <FancyImage image={d.our_philosophy_image} classes='bg-gray-100' x={20} y={20} />
            </div>
          </div>
          <SectionHeader>Our Culture</SectionHeader>
          <div className='flex flex-col justify-between gap-16 lg:flex-row'>
            <div className='flex-1'>
              <Paragraph>
                <ReactMarkdown>{d.our_culture}</ReactMarkdown>
              </Paragraph>
              <Link to='/careers'>
                <Button type='primary'>Come work with us</Button>
              </Link>
            </div>
            <div>
              <FancyImage image={d.our_culture_image} classes='bg-gray-100' x={20} y={20} />
            </div>
          </div>
          <SectionHeader>How does Viranim do it?</SectionHeader>
          <div id='seehowwedoit'>
            <Paragraph>
              <ReactMarkdown>{d.how_does_viranim_do_it}</ReactMarkdown>
            </Paragraph>
          </div>
          <SectionHeader>Meet Amy</SectionHeader>
          <div className='flex flex-col justify-between gap-8 md:gap-16 lg:flex-row'>
            <div className='md:w-72'>
              <FancyImage image={d.meet_amy_image} classes='bg-gray-100' x={-20} y={20} />
              <h2 className='mt-8 text-3xl font-thin text-center md:text-left text-primary'>
                Amy Fowers
              </h2>
              <h3 className='italic text-center text-gray-600 md:text-left'>
                President
              </h3>
            </div>
            <div className='flex-1'>
              <Paragraph>
                <ReactMarkdown>{d.meet_amy}</ReactMarkdown>
              </Paragraph>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  )
}

const AboutDescription = ({ desc }) => {
  return (
    <>
      <p className='mb-8 text-lg md:text-2xl'>{desc}</p>
      <div>
        <Link to='#seehowwedoit'>
          <Button type='primary'>See how we do it</Button>
        </Link>
      </div>
    </>
  )
}

export default about

export const Head = () => <SEO title='Viranim - About Us' />

export const query = graphql`
  query AboutQuery {
    aboutYaml {
      how_does_viranim_do_it
      meet_amy
      our_culture
      our_philosophy
      subheading
      heading
      our_culture_image
      meet_amy_image
      our_philosophy_image
      header_image
    }
    servicesYaml {
      naics_codes
    }
  }
`
